import React from "react"
import { StaticQuery, graphql } from "gatsby"
import * as styles from "../../../styles/components/chart.module.scss"
import nl2br from "react-nl2br"

// markup
class BubbleChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      chartData: props.chartData,
      dataSourceUrl: props.defaultItem,
      csvSourceUrl: `${props.chartData}-${props.defaultItem}`
    }
    
    this.filePath = "/images/chart/bubble-" // 画像データ格納先
    this.csvPath = "/csv/bubble-chart/"
    this.handleChange = this.handleChange.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
  }

  // グラフ切り替えメニュー
  handleChange(e) {
    let name = e.target.name
    this.setState({[name]: e.target.value})
    this.handleBlur(e);
  }

  // データソースの切り替え
  handleBlur(e) {
    this.setState({dataSourceUrl: e.target.value})
    this.setState({csvSourceUrl: `${this.state.chartData}-${e.target.value}`})
  }
  
  // config setter
  setConfig(data) {
    this.config = data
  }

  // config getter
  getConfig() {
    return this.config
  }

  // チャートの設定
  async setChartData() {
    // データの読み込み
    //let dataSource = `${this.filePath}${this.state.chartData}-${this.state.dataSourceUrl}.svg`
  }

  componentDidMount() {
    this.setChartData()
  }

  componentDidUpdate(prevState) {
    if (prevState.dataSourceUrl !== this.state.dataSourceUrl ) {
      this.setChartData()
    }
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query allBubbleChartJsonQuery {
            settings: allBubbleChartJson {
              edges {
                node {
                  select {
                    geothermalCompetitiveness {
                      label
                      name
                    }
                    hydrogenCompetitiveness {
                      label
                      name
                    }
                    hydrogenElementTech {
                      label
                      name
                    }
                    pvCompetitiveness {
                      label
                      name
                    }
                    pvElementTech {
                      label
                      name
                    }
                    windCompetitiveness {
                      label
                      name
                    }
                    windElementTech {
                      label
                      name
                    }
                  }
                  source {
                    geothermalCompetitiveness {
                      explain
                      sourceLink
                      sourceText
                    }
                    hydrogenCompetitiveness {
                      explain
                      sourceLink
                      sourceText
                    }
                    hydrogenElementTech {
                      explain
                      sourceLink
                      sourceText
                    }
                    pvCompetitiveness {
                      explain
                      sourceLink
                      sourceText
                    }
                    pvElementTech {
                      explain
                      sourceLink
                      sourceText
                    }
                    windCompetitiveness {
                      explain
                      sourceLink
                      sourceText
                    }
                    windElementTech {
                      explain
                      sourceLink
                      sourceText
                    }
                  }
                  head {
                    windCompetitiveness
                    windElementTech
                    pvCompetitiveness
                    pvElementTech
                    geothermalCompetitiveness
                    hydrogenCompetitiveness
                    hydrogenElementTech
                  }
                }
              }
            }
          }
        `}
        render={data => (
          <>
            { this.setConfig(data.settings.edges[0].node) }
            <div className={styles.chartTitle}>
              <h2>{data.settings.edges[0].node.head[this.state.chartData]}</h2>
            </div>
            <div className={styles.chartFilter}>
              <div className={styles.chartFilterInner}>
                <select name="type" value={this.state.type} onChange={this.handleChange}>
                  { data.settings.edges[0].node.select[this.state.chartData].map((item, id ) => {
                    return <option key={id} value={item.label}>{item.name}</option>
                  }) }
                </select>
              </div>
            </div>
            <img src={`${this.filePath}${this.state.chartData}-${this.state.dataSourceUrl}.svg`} alt="" width={this.props.width} />
            <div className={styles.chartNote}>
              { (data.settings.edges[0].node.source[this.state.chartData].sourceLink) ? 
                <p className={styles.chartSource}><a href={data.settings.edges[0].node.source[this.state.chartData].sourceLink} target="_blank" rel="noreferrer noopener">{data.settings.edges[0].node.source[this.state.chartData].sourceText}</a></p> :
                <p className={styles.chartSource}>{data.settings.edges[0].node.source[this.state.chartData].sourceText}</p>
              }
              <p className={styles.chartExplain}>{nl2br(data.settings.edges[0].node.source[this.state.chartData].explain)}</p>
              <p><a className={styles.chartDataDownload} href={`${this.csvPath}${this.state.csvSourceUrl}.csv`}>データダウンロード</a></p>
            </div>
          </>
        )}
      />
    )　
  }
}
export default BubbleChart
