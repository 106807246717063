import React from "react"
//import { StaticImage } from 'gatsby-plugin-image'
import { graphql } from "gatsby"

import * as styles from "../../styles/page/second.module.scss"
import * as dashboardStyles from "../../styles/page/dashboard.module.scss"
import * as chartStyles from "../../styles/components/chart.module.scss"

// 共通コンポーネント
import Meta from "../../components/layouts/meta"
import Layout from "../../components/layouts/default"
import BreadCrumb from "../../components/layouts/bread-crumb"

import SearchFilter from "../../components/parts/chart/search-filter"
import BarStacked from "../../components/parts/chart/bar-stacked"
import Patent from "../../components/parts/chart/patent"
import BubbleChart from "../../components/parts/chart/bubble-chart"
import ImageBox from "../../components/parts/image-box"

// markup
class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      s02_1_2_2: false,
      s02_1_2_4: false,
      s02_1_2_5: false,
      s02_1_2_6: false,
      s02_1_2_7: false
    }

    this.searchInit = {
      filter1Init: 1,
      filter2Init: 1,
      filter3Init: 'technology-trend',
      current: props.location.pathname
    }
    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
  }

  openModal(e) {
    const name = e.currentTarget.getAttribute('data-name')
    this.setState({
      [name] : true
    })
  }
  closeModal(e) {
    const name = e.currentTarget.getAttribute('data-name')
    this.setState({
      [name] : false
    })
  }

  render() {
    const markdownRemark = this.props.data.markdownRemark
    const { frontmatter } = markdownRemark
    const { title, description, slug } = frontmatter

    return (
      <Layout>
        <Meta 
          title={title}
          description={description}
          path={slug}
        />

        <BreadCrumb
          parentLink="/dashboard/"
          parentTitle="ダッシュボード"
          currentTitle={title}
        />

        <div className={styles.pageTitle}>
          <h1 className={styles.pageTitleHeading}>{title}</h1>
        </div>

        <SearchFilter {...this.searchInit} />
        
        <main className={styles.pageContents}>
          
          <div className={dashboardStyles.chartArea}>
            <div className={dashboardStyles.chartAreaItem}>
              <div className={chartStyles.chartTitle}>
                <h2>水素還元製鉄コスト</h2>
              </div>
              <div className={chartStyles.chartImageWrapper}>
                <button className={styles.modalLink} onClick={this.openModal} data-name={"s02_2_2_1"}>
                  <img src={'../../images/chart/hydrogen-stacked-bar-1.png'} alt="" width={559} />
                </button>
                <ImageBox
                  open={this.state.s02_2_2_1}
                  close={this.closeModal}
                  name={"s02_2_2_1"}
                  src={'/images/chart/hydrogen-stacked-bar-1.png'}
                  alt="水素還元製鉄コスト"
                  width={1129}
                  height={568} />
              </div>
              <div className={chartStyles.chartNote}>
                <p className={chartStyles.chartSource}>出所）<a href="https://www.iea.org/reports/energy-technology-perspectives-2020	" target="_blank" rel="noreferrer noopener">IEA, ”Energy Technology Perspectives 2020”</a>（2020年9月）</p>
                <p className={chartStyles.chartExplain}>製鉄方式別のコスト（世界）をコスト項目毎の内訳とともに表示。<br />
                コストは地域により異なるため下限コストを記載し、地域差は「Range」として計上している。<br />
                <br />
                BF-BOF：blast furnace-basic oxygen furnace<br />
                DRI-EAF：direct reduced iron-electric arc furnace<br />
                SR-BOF：smelting reduction-basic oxygen furnace<br />
                Fixed OPEX：運転費（固定分）、CAPEX：設備投資額</p>
              </div>
            </div>

            <div className={dashboardStyles.chartAreaItem}>
              <BarStacked id="hydrogenStation" chartData="hydrogenStation" width="100%" height="420px" />
            </div>
          </div>

          <div className={dashboardStyles.chartArea}>
            <div className={dashboardStyles.chartAreaItem}>
              <div className={chartStyles.chartTitle}>
                <h2>FCVコスト 乗用車</h2>
              </div>
              <button className={styles.modalLink} onClick={this.openModal} data-name={"s02_2_2_3"}>
                  <img src={'../../images/chart/fcv-cost-passenger-car.png'} alt="" width={559} />
                </button>
                <ImageBox
                  open={this.state.s02_2_2_3}
                  close={this.closeModal}
                  name={"s02_2_2_3"}
                  src={'/images/chart/fcv-cost-passenger-car.png'}
                  alt="FCVコスト 乗用車"
                  width={1133}
                  height={829} />
              <div className={chartStyles.chartNote}>
                <p className={chartStyles.chartSource}>出所）<a href="https://hydrogencouncil.com/en/path-to-hydrogen-competitiveness-a-cost-perspective/" target="_blank" rel="noreferrer noopener">Hydrogen Council, "Path to hydrogen competitiveness A cost perspective"</a>（2020年1月）</p>
                <p className={chartStyles.chartExplain}>自動車の耐用年数における車両価格、維持費、燃料費を総走行距離で除した値（TCO; Total cost of ownership）を年次別、車種別に表示。<br />
                横軸は車両の導入年次、グラフ右の数値は2030年におけるコストの項目別内訳を示す。<br />
                <br />
                FCEV：燃料電池自動車、BEV：電気自動車、ICE：内燃エンジン車</p>
              </div>
            </div>

            <div className={dashboardStyles.chartAreaItem}>
              <div className={chartStyles.chartTitle}>
                <h2>FCVコスト トラック</h2>
              </div>
              <button className={styles.modalLink} onClick={this.openModal} data-name={"s02_2_2_4"}>
                  <img src={'../../images/chart/fcv-cost-pruck.png'} alt="" width={559} />
                </button>
                <ImageBox
                  open={this.state.s02_2_2_4}
                  close={this.closeModal}
                  name={"s02_2_2_4"}
                  src={'/images/chart/fcv-cost-pruck.png'}
                  alt="FCVコスト トラック"
                  width={1135}
                  height={824} />
              <div className={chartStyles.chartNote}>
                <p className={chartStyles.chartSource}>出所）<a href="https://hydrogencouncil.com/en/path-to-hydrogen-competitiveness-a-cost-perspective/" target="_blank" rel="noreferrer noopener">Hydrogen Council, "Path to hydrogen competitiveness A cost perspective"</a>（2020年1月）</p>
                <p className={chartStyles.chartExplain}>自動車の耐用年数における車両価格、維持費、燃料費を総輸送量（トンキロ）で除した値（TCO; Total cost of ownership）を年次別、車種別に表示。<br />
                横軸は車両の導入年次、グラフ右の数値は2030年における中型燃料電池トラックのコストの項目別内訳を示す。<br />
                FCEV：燃料電池自動車、BEV：電気自動車、ICE：内燃エンジン車<br />
                LCV：小型商用車、MDT：中型トラック、HDT：大型トラック</p>
              </div>
            </div>
          </div>

          <div className={dashboardStyles.chartArea}>
            <div className={dashboardStyles.chartAreaItem}>
              <BubbleChart id="hydrogenCompetitiveness" chartData="hydrogenCompetitiveness" defaultItem="pefc" width="100%" height="420px" />
            </div>

            <div className={dashboardStyles.chartAreaItem}>
              <BubbleChart id="hydrogenElementTech" chartData="hydrogenElementTech" defaultItem="electrode" width="100%" height="420px" />
            </div>
          </div>

          <div className={dashboardStyles.chartArea}>
            <div className={dashboardStyles.chartAreaItem}>
              <Patent id="patent" chartData="hydrogen" width="100%" height="420px" />
            </div>

            <div className={dashboardStyles.chartAreaItem}>
              
            </div>
          </div>

        </main>
      </Layout>
    )
  }
}
export default IndexPage

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: {slug: {eq: $slug}}) {
      html
      frontmatter {
        title
        description
        slug
      }
    }
  }
`